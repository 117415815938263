import React, { useState, useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Loader from '../components/loader/loader';

import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, Avatar, Breadcrumb, Dropdown } from 'antd';
import { FiGrid, FiArchive, FiUsers, FiTool, FiShoppingCart, FiPackage, FiShoppingBag, FiLogOut, FiMenu, } from "react-icons/fi";
import { DatePicker } from 'antd';
import {
  FcSupport, FcContacts, FcPackage, FcSettings, FcSurvey, FcBusinessman,
  FcComboChart, FcDocument, FcMultipleDevices, FcDiploma1, FcImport, FcDisclaimer
} from "react-icons/fc";

import { DataContext } from '../init/getData';
import SessionModal from './sessionModal';
import EndSessionModal from './endSessionModal';

// Lazy-loaded components
const DashboardLazy = React.lazy(() => import('../dashboard/dashboard'));

//Daily Entry
const DailyEntryLazy = React.lazy(() => import('../dailyEntry/dailyEntry'));

//Accounts
const ChartOfAccountLazy = React.lazy(() => import('../accountTab/chart/chart'));
const AuxAccountLazy = React.lazy(() => import('../accountTab/auxAccount/auxAccount'));
const GroupAccountLazy = React.lazy(() => import('../accountTab/group/group'));

//Purchase
const PurchaseInvoiceLazy = React.lazy(() => import('../purchaseTab/purchaseInvoice/purchaseInvoice'));
const ExpenseInvoiceLazy = React.lazy(() => import('../purchaseTab/expenseInvoice/expenseInvoice'));
const PaymentLazy = React.lazy(() => import('../purchaseTab/payments/payments'));
const SupplierLazy = React.lazy(() => import('../purchaseTab/supplier/supplier'));

//Sale
const CustomerLazy = React.lazy(() => import('../salesTab/customer/customer'));
const SaleInvoiceLazy = React.lazy(() => import('../salesTab/salesInvoice/salesInvoice'));
const ReceiptLazy = React.lazy(() => import('../salesTab/receipts/receipts'));

//Inventory
const ProductLazy = React.lazy(() => import('../inventoryTab/product/product'));
const CategoryLazy = React.lazy(() => import('../inventoryTab/category/category'));
const BrandLazy = React.lazy(() => import('../inventoryTab/brand/brand'));
const SeasonLazy = React.lazy(() => import('../inventoryTab/season/season'));
const WarehouseLazy = React.lazy(() => import('../inventoryTab/warehouse/warehouse'));

//Report
const TrialBalanceLazy = React.lazy(() => import('../reportTab/trialBalance/trialBalance'));
const AccountStatementLazy = React.lazy(() => import('../reportTab/accountStatement/accountStatement'));
const SessionLazy = React.lazy(() => import('../reportTab/session/session'));

//Stock
const StockMovementLazy = React.lazy(() => import('../stockTab/stockMovement/stockMovement'));
const StockValueLazy = React.lazy(() => import('../stockTab/stockValue/stockValue'));
const StockControlLazy = React.lazy(() => import('../stockTab/stockControl/stockControl'));
const StockTransferLazy = React.lazy(() => import('../stockTab/stockTransfer/stockTransfer'));

//Tools
const StockToolsLazy = React.lazy(() => import('../toolsTab/stockTools/stockTools'));

//Settings
const GeneralLazy = React.lazy(() => import('../settingTab/general/general'));
const LinkAccountLazy = React.lazy(() => import('../settingTab/linkAccount/linkAccount'));
const YearLazy = React.lazy(() => import('../settingTab/year/year'));
const UserLazy = React.lazy(() => import('../settingTab/user/user'));
const RoleLazy = React.lazy(() => import('../settingTab/role/role'));
const CurrencyLazy = React.lazy(() => import('../settingTab/currency/currency'));

//retail
const PosLazy = React.lazy(() => import('../retailTab/pos/pos'));


const { Header, Sider, Content } = Layout;

const items = [
  {
    key: 'Dashboard',
    label: 'Dashboard',
    icon: <FcComboChart size={22} />,
    children: [
      {
        key: 'purchaseInvoice',
        label: 'Option 1',
      },
    ]

  },
  {
    key: 'DailyEntry',
    label: 'DailyEntry',
    icon: <FcDiploma1 size={22} />,
  },
  {
    key: 'Accounts',
    icon: <FcContacts size={22} />,
    label: 'Accounts',
    children: [
      {
        key: 'Chart Of Accounts',
        label: 'Chart Of Accounts',
      },
      {
        key: 'My Accounts',
        label: 'My Accounts',
      },
      {
        key: 'Groups',
        label: 'Groups',
      },
    ]
  },
  {
    key: 'Purchases',
    icon: <FcDocument size={22} />,
    label: 'Purchases',
    children: [
      {
        key: 'Supplier',
        label: 'Supplier',
      },
      {
        key: 'Purchase Invoice',
        label: 'Purchase Invoice',
      },
      {
        key: 'Purchase Return',
        label: 'Purchase Return',
      },
      {
        key: 'Purchase Order',
        label: 'Purchase Order',
      },
      {
        key: 'Expense Invoice',
        label: 'Expense Invoice',
      },
      {
        key: 'Payments',
        label: 'Payments',
      },
    ]
  },
  {
    key: 'Sales',
    icon: <FiShoppingCart strokeWidth={2.5} color={'red'} size={20} />,
    label: 'Sales',
    children: [
      {
        key: 'Customer',
        label: 'Customer',
      },
      {
        key: 'Sales Invoice',
        label: 'Sales Invoice',
      },
      {
        key: 'Sales Return',
        label: 'Sales Return',
      },
      {
        key: 'Sales Order',
        label: 'Sales Order',
      },
      {
        key: 'Receipt',
        label: 'Receipt',
      },
    ]
  },
  {
    key: 'Stock',
    icon: <FcPackage size={22} />,
    label: 'Stock',
    children: [
      {
        key: 'Stock Movement',
        label: 'Stock Movement',
      },
      {
        key: 'Stock Value',
        label: 'Stock Value',
      },
      {
        key: 'Stock Control',
        label: 'Stock Control',
      },
      {
        key: 'Stock Transfer',
        label: 'Stock Transfer',
      },
    ]
  },
  {
    key: 'Inventory',
    icon: <FiPackage strokeWidth={2.5} color={'green'} size={20} />,
    label: 'Inventory',
    children: [
      {
        key: 'Products',
        label: 'Products',
      },
      {
        key: 'Categories',
        label: 'Categories',
      },
      {
        key: 'Brands',
        label: 'Brands',
      },
      {
        key: 'Seasons',
        label: 'Seasons',
      },
      {
        key: 'VatGroup',
        label: 'VatGroup',
      },
      {
        key: 'Warehouse',
        label: 'Warehouse',
      },
    ]
  },
  {
    key: 'Reports',
    label: 'Reports',
    icon: <FcSurvey size={22} />,
    children: [
      {
        key: 'Trial Balance',
        label: 'Trial Balance',
      },
      {
        key: 'Account Statement',
        label: 'Account Statement',
      },
      {
        key: 'Sessions',
        label: 'Sessions',
      }
    ]

  },
  ,
  {
    key: 'Tools',
    label: 'Tools',
    icon: <FcSupport size={22} />,
    children: [
      {
        key: 'Stock Tools',
        label: 'Stock Tools',
      },
      {
        key: 'Barcode Generator',
        label: 'Barcode Generator',
      },
      {
        key: 'BackUp',
        label: 'BackUp',
      },
    ]

  },
  {
    key: 'Settings',
    label: 'Settings',
    icon: <FcSettings size={22} />,
    children: [
      {
        key: 'General',
        label: 'General',
      },
      {
        key: 'User',
        label: 'User',
      },
      {
        key: 'Role',
        label: 'Role',
      },
      {
        key: 'Link Account',
        label: 'Link Account',
      },
      {
        key: 'Year',
        label: 'Year',
      },
      {
        key: 'Company',
        label: 'Company',
      },
      {
        key: 'Branch',
        label: 'Branch',
      },
      {
        key: 'Project',
        label: 'Project',
      },
      {
        key: 'Currency',
        label: 'Currency',
      },
    ]

  },
  {
    type: 'divider',
  },
  {
    key: 'Retail',
    label: 'Retail',
    icon: <FcMultipleDevices size={22} />,
    children: [
      {
        key: 'Pos',
        label: 'POS',
      },
      {
        key: 'DineIN',
        label: 'Dine In',
      },
    ]

  },

];




const LeftMenu = (props) => {

  const { userData, setAccess } = useContext(DataContext);

  const [collapsed, setCollapsed] = useState(false);
  const [breadcrumbItems, setBreadcrumbItems] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showEndSessionModal, setShowEndSessionModal] = useState(false);
  const navigate = useNavigate();

  const userMenu = [
    {
      label: 'View Profile',
      key: '0',
      icon: <FcBusinessman size={20} />,
    },
    {
      label: 'View Session',
      key: '1',
      icon: <FcDocument size={19} />,
      onClick: () => setShowSessionModal(!showSessionModal),
    },
    {
      label: 'End Session',
      key: '2',
      icon: <FcDisclaimer size={20} />,
      onClick: () => setShowEndSessionModal(!showEndSessionModal),
    },
    {
      type: 'divider',
    },
    {
      label: 'LogOut',
      key: '3',
      icon: <FcImport size={20} />,
      onClick: () => logOut(),
    },
  ];

  const logOut = () => {
    setAccess(false);
  }


  const handleMenuClick = ({ key, keyPath }) => {
    navigate(`/${key}`);
    const title = keyPath.at(-1);
    if (keyPath.length == 1) {
      setBreadcrumbItems([{ title: 'Home' }, { title: title }])
    } else {
      setBreadcrumbItems([{ title: title }, { title: key }])
    }

  };




  return (
    <Layout style={{ userSelect: 'none' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={items}
          onClick={handleMenuClick}
          style={{ height: '100vh', overflowY: 'auto', userSelect: 'none' }}
          className="custom-scrollbar"
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: '#fefefe', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
            <Breadcrumb
              style={{ margin: '0 16px' }}
              items={breadcrumbItems}
            />
          </div>
          <Dropdown menu={{ items: userMenu }} trigger={['click']} >
            <div
              style={{
                marginRight: '2%',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: 'pointer',
              }}
            >
              <Avatar size="small" icon={<UserOutlined />} />
              <span>{userData.name}</span>
              <CaretDownOutlined />
            </div>
          </Dropdown>
        </Header>

        <Content
          style={{
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            background: '#f9fafa',
            borderRadius: 8,
            overflowY: 'auto',
            maxHeight: '90vh'
          }}
        >
          <Routes>

            {/* Dashboard */}
            <Route path="dashboard" element={<React.Suspense fallback={<Loader />}><DashboardLazy /></React.Suspense>} />

            {/* DailyEntry */}
            <Route path="DailyEntry" element={<React.Suspense fallback={<Loader />}><DailyEntryLazy /></React.Suspense>} />

            {/* Accounts */}
            <Route path="Chart Of Accounts" element={<React.Suspense fallback={<Loader />}><ChartOfAccountLazy /></React.Suspense>} />
            <Route path="My Accounts" element={<React.Suspense fallback={<Loader />}><AuxAccountLazy /></React.Suspense>} />
            <Route path="Groups" element={<React.Suspense fallback={<Loader />}><GroupAccountLazy /></React.Suspense>} />

            {/* Purchase */}
            <Route path="Supplier" element={<React.Suspense fallback={<Loader />}><SupplierLazy/></React.Suspense>} />
            <Route path="Purchase Invoice" element={<React.Suspense fallback={<Loader />}><PurchaseInvoiceLazy type={'PurchaseInvoice'} isDebit={true} isOrder={false} /></React.Suspense>} />
            <Route path="Purchase Return" element={<React.Suspense fallback={<Loader />}><PurchaseInvoiceLazy type={'PurchaseReturn'} isDebit={false} isOrder={false} /></React.Suspense>} />
            <Route path="Purchase Order" element={<React.Suspense fallback={<Loader />}><PurchaseInvoiceLazy type={'PurchaseOrder'} isDebit={true} isOrder={true} /></React.Suspense>} />
            <Route path="Expense Invoice" element={<React.Suspense fallback={<Loader />}><ExpenseInvoiceLazy type={'ExpenseInvoice'} isDebit={true} isOrder={false} Payment /></React.Suspense>} />
            <Route path="Payments" element={<React.Suspense fallback={<Loader />}><PaymentLazy type={'Payment'} isDebit={true} isOrder={false} /></React.Suspense>} />


            {/* Sales */}
            <Route path="Customer" element={<React.Suspense fallback={<Loader />}><CustomerLazy/></React.Suspense>} />
            <Route path="Sales Invoice" element={<React.Suspense fallback={<Loader />}><SaleInvoiceLazy type={'SalesInvoice'} isDebit={false} isOrder={false} /></React.Suspense>} />
            <Route path="Sales Return" element={<React.Suspense fallback={<Loader />}><SaleInvoiceLazy type={'SalesReturn'} isDebit={true} isOrder={false} /></React.Suspense>} />
            <Route path="Sales Order" element={<React.Suspense fallback={<Loader />}><SaleInvoiceLazy type={'SalesOrder'} isDebit={false} isOrder={true} /></React.Suspense>} />
            <Route path="Receipt" element={<React.Suspense fallback={<Loader />}><ReceiptLazy type={'Receipt'} isDebit={false} isOrder={true} /></React.Suspense>} />


            {/* Inventory */}
            <Route path="Products" element={<React.Suspense fallback={<Loader />}><ProductLazy /></React.Suspense>} />
            <Route path="Categories" element={<React.Suspense fallback={<Loader />}><CategoryLazy /></React.Suspense>} />
            <Route path="Brands" element={<React.Suspense fallback={<Loader />}><BrandLazy /></React.Suspense>} />
            <Route path="Seasons" element={<React.Suspense fallback={<Loader />}><SeasonLazy /></React.Suspense>} />
            <Route path="Warehouse" element={<React.Suspense fallback={<Loader />}><WarehouseLazy /></React.Suspense>} />

            {/* Reports */}
            <Route path="Trial Balance" element={<React.Suspense fallback={<Loader />}><TrialBalanceLazy /></React.Suspense>} />
            <Route path="Account Statement" element={<React.Suspense fallback={<Loader />}><AccountStatementLazy /></React.Suspense>} />
            <Route path="Sessions" element={<React.Suspense fallback={<Loader />}><SessionLazy /></React.Suspense>} />


            {/* Stock Movement */}
            <Route path="Stock Movement" element={<React.Suspense fallback={<Loader />}><StockMovementLazy /></React.Suspense>} />
            <Route path="Stock Value" element={<React.Suspense fallback={<Loader />}><StockValueLazy /></React.Suspense>} />
            <Route path="Stock Control" element={<React.Suspense fallback={<Loader />}><StockControlLazy /></React.Suspense>} />
            <Route path="Stock Transfer" element={<React.Suspense fallback={<Loader />}><StockTransferLazy /></React.Suspense>} />

            {/* Tools */}
            <Route path="Stock Tools" element={<React.Suspense fallback={<Loader />}><StockToolsLazy /></React.Suspense>} />

            {/* Setting */}
            <Route path="General" element={<React.Suspense fallback={<Loader />}><GeneralLazy /></React.Suspense>} />
            <Route path="Link Account" element={<React.Suspense fallback={<Loader />}><LinkAccountLazy /></React.Suspense>} />
            <Route path="Year" element={<React.Suspense fallback={<Loader />}><YearLazy /></React.Suspense>} />
            <Route path="User" element={<React.Suspense fallback={<Loader />}><UserLazy /></React.Suspense>} />
            <Route path="Role" element={<React.Suspense fallback={<Loader />}><RoleLazy /></React.Suspense>} />
            <Route path="Currency" element={<React.Suspense fallback={<Loader />}><CurrencyLazy /></React.Suspense>} />

            {/* Retail */}
            <Route path="Pos" element={<React.Suspense fallback={<Loader />}><PosLazy type={['PosSale', 'PosReturn']} /></React.Suspense>} />

          </Routes>

        </Content>
      </Layout>



      <SessionModal
        showModal={showSessionModal}
        toggleModal={() => setShowSessionModal(!showSessionModal)}
      />

      <EndSessionModal
        showModal={showEndSessionModal}
        toggleModal={() => setShowEndSessionModal(!showEndSessionModal)}
        setAccess={setAccess}
      />


    </Layout>
  );
};
export default LeftMenu;
import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { DataContext } from '../init/getData';
import Loader from '../components/loader/loader';
import { Button, Table, Divider } from 'antd';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { dateTableFormatter } from '../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter, statusFormatter } from '../components/formatters/defFormatter';
import { numberFormatter } from '../components/formatters/numberFormatter';

const EndSessionModal = (props) => {

  const { api, userData, sessionId, settingData, session, companyId, yearId, currencyData } = useContext(DataContext);
  const createdBy = userData.id;
  const userName = userData.name;
  const userRole = userData.role;
  const currency = currencyData.find((item) => item.id === settingData.mainCurrencyId);

  const { showModal, toggleModal, title, setAccess } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(true);
  const [closingBalance, setClosingBalance] = useState(true);
  const [balance, setBalance] = useState(0);

  const cashAccountId = userRole.cashAccountId;
  const varianceAccountId = userRole.varianceAccountId;
  const mainCurrencyId = settingData.mainCurrencyId;
  const openingBalance = session.openingBalance;
  let variance = balance - closingBalance;

  const isDebit = variance > 0 ? true : false;
  variance = variance < 0 ? -variance : variance;
  const totalDebit = isDebit ? variance : 0;
  const totalCredit = !isDebit ? variance : 0;

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (showModal) {
      fetchData();
    }
  }, [showModal]);

  const fetchData = async () => {
    const data = {
      sessionId,
    }
    let res = await api.get("/session/view", { params: data });
    if (!res.data.status) {
      toast.error(res.data.message);
    } else {
      const balance = res?.data?.summary?.totalCredit - res?.data?.summary?.totalDebit;
      const totalBalance = balance + parseFloat(openingBalance);
      setBalance(totalBalance);
    }
    setIsLoading(false)
  };

  const journalVoucher = {
    createdBy: createdBy,
    sessionId: sessionId,
    companyId: companyId,
    yearId: yearId,
    accountId: null,
    date: today,
    type: 'Variance',
    totalDebit: totalDebit,
    totalCredit: totalCredit,
    details: '',
    paymentType: 'cash',
    isDebit: isDebit,
    currencyId: mainCurrencyId,
    rate: currency?.rate,
  }

  const transactions = [
    {
      type: 'variance',
      accountId: varianceAccountId,
      isDebit: isDebit,
      debit: isDebit ? variance : 0,
      credit: !isDebit ? variance : 0,
      totalDebit: isDebit ? variance : 0,
      totalCredit: !isDebit ? variance : 0,
      rate: currency.rate,
      currencyId: settingData.mainCurrencyId,
    },
    {
      type: 'Account Payable',
      accountId: cashAccountId,
      isDebit: !isDebit,
      debit: !isDebit ? variance : 0,
      credit: isDebit ? variance : 0,
      totalDebit: !isDebit ? variance : 0,
      totalCredit: isDebit ? variance : 0,
      rate: currency.rate,
      currencyId: settingData.mainCurrencyId,
    },
  ]



  const handleSubmit = async () => {
    setLoadingButton(true);
    const data = {
      journalVoucher,
      transactions,
      sessionId,
      closingBalance,
      balance,
      variance,
    }
    let res = await api.post("/session/end", data);
    if (!res.data.status) {
      toast.error(res.data.message);

    } else {
      toast.success(res.data.message);
      setAccess(false);
    }
    setLoadingButton(false);
  };





  return (
    <Modal show={showModal} backdrop="static" size={'md'} keyboard={false} onHide={toggleModal}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }} >
      <Modal.Header style={{ background: "#eb5355" }}>
        <Modal.Title style={{ color: "white" }} >End Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Row>
          <Col>
            <Form.Label>Balance</Form.Label>
            <Form.Control type="number" step="any"
              onChange={e => setClosingBalance(e.target.value)}
              value={closingBalance} />
          </Col>
        </Row>


        <div className="modal-footer" style={{ justifyContent: 'center' }}>
          <Button size="large" variant="filled" onClick={toggleModal}>Close</Button>
          <Button type="primary" size="large" onClick={handleSubmit} danger>End Session</Button>
        </div>

      </Modal.Body>
    </Modal>
  )
}


export default EndSessionModal;
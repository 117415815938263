import React, { useState, useEffect, useContext } from 'react';
import { LoadingOutlined } from '@ant-design/icons';


import { Flex, Spin } from 'antd';


function Loader(props) {

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 999,
      }}
    >
      <Spin indicator={<LoadingOutlined spin />} size="large" />
    </div>
  )
}

export default Loader;

import React, { useState, useEffect, createContext } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import CartData from './cartData';
import Loader from '../components/loader/loader';

import { Flex, Spin } from 'antd';

import { link } from '../dynamic/api';

export const DataContext = createContext();
export const CartContext = createContext();

function GetData(props) {

  const { setAccess, selectedCompany, selectedYear, expireyDate, api, devSetting, userData, session } = props;

  const companyId = selectedCompany.id;
  const yearId = selectedYear.id;
  const sessionId = session.id;

  const [isLoading, setIsLoading] = useState(true);
  const [deliveryData, setDeliveryData] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [printerData, setPrinterData] = useState([]);

  //Account Tab
  const [chartData, setChartData] = useState([]);
  const [parentAccountData, setParentAccountData] = useState([]);
  const [auxAccountData, setAuxAccountData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [accountGroupData, setAccountGroupData] = useState([]);
  const [customerAccountData, setCustomerAccountData] = useState([]);
  const [supplierAccountData, setSupplierAccountData] = useState([]);
  const [expenseAccountData, setExpenseAccountData] = useState([]);

  const [devData, setDevData] = useState([]);
  const [yearData, setYearData] = useState([]);

  // Invoice Data
  const [invoiceData, setInvoiceData] = useState([]);


  // setting
  const [projectData, setProjectData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [vatGroupData, setVatGroupData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  // inventory

  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [seasonData, setSeasonData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [salableData, setSalableData] = useState([]);

  // Tempor run
  useEffect(() => {


    const fetchData = async () => {
      try {
        const dataArray = ['setting', 'currency']
        await refData(dataArray, companyId);
        setIsLoading(false);
      } catch (error) {
        console.error('Error refreshing data Contact Support');
      }
    };
    if (isLoading) {
      fetchData();
    }
  }, []);



  // Get Jv Data
  const refData = async (dataArray, companyId) => {

    try {
      const data = {
        dataArray,
        companyId,
      }
      const response = await api.get(`/getData/refData`, { params: data });
      if (response) {
        const data = response?.data?.data;

        // accounts
        if (dataArray.includes('account')) { setAccountData(data.accountData?.data) };
        if (dataArray.includes('chart')) { setChartData(data.chartData?.data) };
        if (dataArray.includes('parentAccount')) { setParentAccountData(data.parentAccountData?.data) };
        if (dataArray.includes('auxAccount')) { setAuxAccountData(data.auxAccountData?.data) };
        if (dataArray.includes('accountGroup')) { setAccountGroupData(data.accountGroupData?.data) };
        if (dataArray.includes('customerAccount')) { setCustomerAccountData(data.customerAccountData?.data) };
        if (dataArray.includes('supplierAccount')) { setSupplierAccountData(data.supplierAccountData?.data) };
        if (dataArray.includes('expenseAccount')) { setExpenseAccountData(data.expenseAccountData?.data) };


        // setting
        if (dataArray.includes('branch')) { setBranchData(data.branchData?.data) };
        if (dataArray.includes('department')) { setDepartmentData(data.departmentData?.data) };
        if (dataArray.includes('vatGroup')) { setVatGroupData(data.vatGroupData?.data) };
        if (dataArray.includes('project')) { setProjectData(data.projectData?.data) };
        if (dataArray.includes('setting')) { setSettingData(data.settingData?.data) };
        if (dataArray.includes('company')) { setCompanyData(data.companyData?.data) };
        if (dataArray.includes('currency')) { setCurrencyData(data.currencyData?.data) };
        if (dataArray.includes('role')) { setRoleData(data.roleData?.data) };

        //inventory
        if (dataArray.includes('product')) { setProductData(data.productData?.data) };
        if (dataArray.includes('salable')) { setSalableData(data.salableData?.data) };
        if (dataArray.includes('brand')) { setBrandData(data.brandData?.data) };
        if (dataArray.includes('category')) { setCategoryData(data.categoryData?.data) };
        if (dataArray.includes('season')) { setSeasonData(data.seasonData?.data) };
        if (dataArray.includes('warehouse')) { setWarehouseData(data.warehouseData?.data) };
      }
    } catch (error) {
      toast.error('' + error);
    }
  };


  // Get Invoice
  const getInvoiceData = async (accountId, type) => {

    try {
      const data = {
        accountId,
        type,
      }
      const response = await api.get(`/journalVoucher/getInvoice`, { params: data });
      if (response) {
        const data = response?.data?.data;
        setInvoiceData(data);
      }
    } catch (error) {
      toast.error('' + error);
    }
  };

  return (
    <div>

      <DataContext.Provider
        value={{
          expireyDate,
          api,

          roleData,
          setAccess,
          session,
          sessionId,

          // selected data
          selectedCompany,
          selectedYear,
          companyId,
          yearId,

          devData,
          yearData,
          roleData,
          deliveryData,
          devSetting,
          refData,

          //account
          accountData,
          parentAccountData,
          chartData,
          auxAccountData,
          accountGroupData,
          customerAccountData,
          supplierAccountData,
          expenseAccountData,

          // settings
          settingData,
          currencyData,
          branchData,
          vatGroupData,
          companyData,
          userData,

          // inventory
          productData,
          salableData,
          categoryData,
          seasonData,
          projectData,
          warehouseData,
          brandData,

          //get Invoice by AccountId
          getInvoiceData,
          invoiceData,
        }}>


        {isLoading &&
          <Spin size="large" tip="Loading your Data" fullscreen />
        }


        {!isLoading &&
          <CartData settingData={settingData} userData={userData} />
        }



      </DataContext.Provider>




    </div>

  );
}


export default GetData;
import './App.css';
import './antTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.min.css';

import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useNavigate } from "react-router-dom";

import LogIn from './login/login';

import toast, { Toaster } from 'react-hot-toast';

import axios from 'axios';
import { link } from './dynamic/api';

const api = axios.create({
  baseURL: link,
  headers: {
    'Content-Type': 'application/json'
  }
});


function App() {

  const [settingData, setSettingData] = useState(null);
  const [devSetting, setDevSetting] = useState(null);



  // Get Dev Settings
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/dev/getAll`);
        if (response) {
          setDevSetting(response.data.data[0]);
        }
      } catch (error) {
        toast.error('' + error);
      }
    };
    fetchData();
  }, []);


  return (
    <>
      {devSetting &&
        <div className="app">
          <Toaster position="top-center" reverseOrder={false} />
          <Routes>
            <Route path="/*" element={<LogIn api={api} devSetting={devSetting} />} index />
          </Routes>
        </div>
      }
    </>
  );
}

export default App;

import React, { useState, useEffect, useContext, createContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import LeftMenu from '../menu/menu';
import { DataContext } from './getData';


export const CartContext = createContext();

const CartData = (props) => {

  
  const { api, userData, settingData, selectedCompany, selectedYear, currencyData, sessionId } = useContext(DataContext);

  const createdBy = parseInt(userData.id);
  const companyId = selectedCompany.id;
  const yearId = selectedYear.id;

  const getCurrency = currencyData?.find(option => option.id === settingData.mainCurrencyId);
  const rate = parseFloat(getCurrency?.rate) || 0;
  const vatRate = parseFloat(getCurrency?.vatRate) || 0;

  const journalVoucher = {
    createdBy: createdBy,
    sessionId: sessionId,
    companyId: companyId,
    yearId: yearId,
    accountId:  null,
    type: '',
    totalDebit: 0,
    totalCredit: 0,
    paymentType: 'cash',
    isDebit: false,
    isOrder: false,
    discountType: 'percentage',
    discountPercentage: 0,
    discountAmount: 0,
    currencyId: settingData.mainCurrencyId,
    rate: rate,
    vatRate: vatRate,

    warehouseId: 1,
    projectId: null,
    branchId: null,
  };


  const cart = [];
  const transactions = [];
  const isVat = true;


  const tab = { journalVoucher, transactions, cart, isVat };

  const [tabData, setTabData] = useState([tab, tab, tab, tab]);

  const setCart = (cart, selectedTab) => {

    let updatedCart = [...cart];

    setTabData((prevTabs) => {
        const updatedTabs = [...prevTabs];
        updatedTabs[selectedTab] = {
            ...updatedTabs[selectedTab],
            cart: updatedCart
        };
        return updatedTabs;
    });
}


  return (
    <div>

      <CartContext.Provider
        value={{
          tabData,
          setTabData,
          setCart,
        }}>
          <LeftMenu/>
      </CartContext.Provider>
    </div>
  );
}
export default CartData;

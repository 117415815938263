import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { DataContext } from '../init/getData';
import Loader from '../components/loader/loader';
import { Button, Table, Divider } from 'antd';
import { Col, Form, Row, DropdownButton, Dropdown } from 'react-bootstrap';
import { dateTableFormatter } from '../dynamic/dateFormatter';
import { typeFormatter, paymentTypeFormatter, statusFormatter } from '../components/formatters/defFormatter';
import { numberFormatter } from '../components/formatters/numberFormatter';

const SessionModal = (props) => {

  const { api, userData, session, sessionId } = useContext(DataContext);
  const userId = userData.id;
  const userName = userData.name;
  const userRole = userData.role.name;


  const { showModal, toggleModal, title, journalVoucher } = props;
  const [isLoading, setIsLoading] = useState(true);

  const [jvs, setJvs] = useState(true);
  const [summary, setSummary] = useState(true);

  useEffect(() => {
    if (showModal) {
      fetchData();
    }
  }, [showModal]);

  const fetchData = async () => {
    const data = {
      sessionId,
    }
    let res = await api.get("/session/view", { params: data });
    if (!res.data.status) {
      toast.error(res.data.message);
      setIsLoading(false)
    } else {
      setIsLoading(false)
      setJvs(res?.data?.groupedData)
      setSummary(res?.data?.summary)
    }
  };


  const JvColumns = [
    { title: 'Type', dataIndex: 'type' },
    {
      title: 'Debit',
      dataIndex: 'totalDebit',
      render: numberFormatter,
    },
    {
      title: 'Credit ',
      dataIndex: 'totalCredit',
      render: numberFormatter,
    },
  ]

  const totalBalance =  (summary?.totalCredit - summary?.totalDebit) + parseFloat(session?.openingBalance);


  return (
    <Modal show={showModal} backdrop="static" size={'md'} keyboard={false} onHide={toggleModal}
      onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }} >
      <Modal.Header style={{ display: 'flex', justifyContent: 'center', background: "#23576a" }}>
        <Modal.Title style={{ color: "white" }} >View Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {isLoading &&
          <Loader />
        }

        {!isLoading &&
          <>
            <Row>
              <Col md={4}>
                <span style={{ fontWeight: 'bold' }}>
                  OpenedAt:
                </span>
              </Col>
              <Col md={8}>
                {dateTableFormatter(session?.openedAt)}
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={4}>
                <span style={{ fontWeight: 'bold' }}>
                  Opening Balance:
                </span>
              </Col>
              <Col md={8}>
                {numberFormatter(session?.openingBalance)}
              </Col>
            </Row>

            <Row className="mb-4">
              <Table columns={JvColumns} dataSource={jvs} pagination={false} />
            </Row>

            <Row className="mb-4">
              <Col md={6}>
              </Col>
              <Col md={3}>
                <span style={{ fontWeight: 'bold' }}>
                  Balance:
                </span>
              </Col>
              <Col md={2}>
                <span style={{ fontWeight: 'bold', color: '#008000' }}>
                  {numberFormatter(totalBalance)}
                </span>
              </Col>
            </Row>
          </>
        }


        <div className="modal-footer" style={{ justifyContent: 'center' }}>
          <Button type="primary" size="large" onClick={toggleModal} danger>Close</Button>
        </div>

      </Modal.Body>
    </Modal>
  )
}


export default SessionModal;